<template>
  <Data :shadow="true" :showMenu="true">
    <template v-slot:header>
      <div v-if="isMobileDevice" class="title title--contract-with-butt">
        <h1>{{ $t('account.settings') }}</h1>
      </div>

      <div
        v-else
        class="title title--contract-with-butt"
        style="text-align: left; max-width: 845px; margin: -30px auto 0"
      >
        <span style="color: gray; font-size: 17px"> {{ $t('account.settings') }}: </span>
        <h1 style="text-align: left; margin-top: 10px">
          <strong>{{ selectedTab }}</strong>
        </h1>
      </div>

      <div
        class="data__tab-select"
        v-bind:class="{ 'select-clicked': selectClicked }"
        @click="displaySelect()"
      >
        {{ selectedTab }}
        <span class="data__tab-hamburger"></span>
      </div>
    </template>

    <tabs
      :class="{ 'select-clicked': selectClicked }"
      :options="{ defaultTabHash: 'first-tab' }"
      :setTab="accountSelectedTab"
      :setSelectedTab="selectedTab !== accountSelectedTab ? '#' + accountSelectedTab : selectedTab"
      :showTabsButtons="isMobileDevice"
      @changed="tabChanged"
    >
      <tab id="personal-information" :name="$t('account.personal_information')">
        <div class="avatar">
          <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>{{ $t('account.drop_image') }}</h3>
          </div>
          <div class="avatar-upload" v-show="!edit">
            <div class="text-center p-2">
              <div class="avatar__text">
                <div>
                  <span class="avatar-edit-title">
                    {{ $t('account.profile_image') }}
                  </span>
                </div>

                <div class="pt-2">
                  <v-btn
                    color="primary"
                    block
                    x-small
                    @click="$refs.upload.$el.querySelector('input[type=file]').click()"
                  >
                    {{ profile.avatar_url ? $t('general.edit') : $t('general.add') }}
                  </v-btn>
                </div>

                <div class="pt-2">
                  <v-btn
                    color="error"
                    block
                    x-small
                    :disabled="!profile.avatar_url"
                    :loading="deletingAvatar"
                    @click="deleteAvatar()"
                  >
                    {{ $t('general.delete') }}
                  </v-btn>
                </div>
              </div>

              <div class="avatar__image">
                <v-avatar
                  size="108"
                  style="cursor: pointer"
                  @click="$refs.upload.$el.querySelector('input[type=file]').click()"
                >
                  <img :src="profileAvatar" alt="profile-avatar" />
                </v-avatar>
              </div>
            </div>

            <div class="text-center p-2">
              <file-upload
                extensions="gif,jpg,jpeg,png,webp"
                accept="image/png,image/gif,image/jpeg,image/webp"
                name="file"
                class="btn btn-primary"
                :post-action="url"
                :drop="!edit"
                :headers="headers"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload"
              />
            </div>
          </div>

          <SigniModal v-model="edit" v-show="files.length && edit">
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="mx-auto avatar-edit-image" v-if="files.length">
                    <img ref="editImage" :src="files[0].url" alt="profile-avatar" />
                  </div>
                </v-col>
              </v-row>

              <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="4">
                  <v-btn @click.prevent="$refs.upload.clear" block outlined>
                    {{ $t('general.cancel') }}
                  </v-btn>
                </v-col>

                <v-col cols="4">
                  <v-btn type="submit" @click.prevent="editSave" block color="primary">
                    {{ $t('general.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </SigniModal>
        </div>

        <form @submit.prevent="updateAccount()" class="mt-5">
          <div class="profile-inputs">
            <v-text-field
              type="text"
              v-model="account.firstname"
              :label="$t('general.name')"
              :placeholder="$t('placeholder.name')"
              :error="$v.account.firstname.$error"
              outlined
            />

            <v-text-field
              type="text"
              v-model="account.lastname"
              :label="$t('general.surname')"
              :placeholder="$t('placeholder.surname')"
              :error="$v.account.lastname.$error"
              outlined
            />

            <v-text-field
              type="email"
              v-model="account.email"
              :label="$t('general.email_simple')"
              :error="$v.account.email.$error"
              outlined
              disabled
            />

            <LanguageSelect
              :label="$t('account.locale')"
              :locale="account.locale"
              :onlyFullyLocalized="true"
              @onLocaleChanged="account.locale = $event"
            ></LanguageSelect>
          </div>

          <div class="profile-buttons">
            <v-btn type="submit" color="primary" block x-large>
              {{ $t('general.save') }}
            </v-btn>
          </div>
        </form>
      </tab>

      <tab id="phone" :name="$t('general.phone')">
        <div class="profile-buttons">
          <template v-if="confirmChangePhone == 0 || confirmChangePhone == 2">
            <p v-if="confirmChangePhone == 2">
              {{ $t('account.phone_edited') }}
            </p>

            <form @submit.prevent="updatePhone()">
              <SPhoneNumberInput
                class="pb-5"
                v-model="phone.mobile"
                required
                @on-validity-change="phoneNumberValid = $event"
              />

              <v-text-field
                name="password"
                v-model="phone.password"
                :label="$t('password.title')"
                :placeholder="$t('password.type')"
                :type="showPassPhone ? 'text' : 'password'"
                :append-icon="showPassPhone ? 'mdi-eye' : 'mdi-eye-off'"
                :error="$v.phone.password.$error"
                outlined
                @click:append="showPassPhone = !showPassPhone"
              />

              <v-btn type="submit" color="primary" block x-large :loading="loadingPhone">
                {{ $t('account.edit_phone') }}
              </v-btn>
            </form>
          </template>

          <template v-if="confirmChangePhone == 1">
            <p>{{ $t('account.add_pin') }}</p>

            <InputPIN ref="inputPin" v-model="pinCode" @confirmPin="confirmPin" />

            <div class="profile-buttons">
              <Button
                :loading="loadingConfirmPin"
                @click="confirmPin()"
                buttonType="submit"
                type="primary"
                >{{ $t('two_factor_auth.send_pin') }}</Button
              >
            </div>
          </template>
        </div>
      </tab>

      <tab id="change-password" :name="$t('password.edit2')">
        <form @submit.prevent="updatePassword()">
          <v-text-field
            name="password"
            v-model="password.password_old"
            :label="$t('password.current')"
            :placeholder="$t('password.min_length')"
            :type="showPassOld ? 'text' : 'password'"
            :append-icon="showPassOld ? 'mdi-eye' : 'mdi-eye-off'"
            :error="password.badPassword || $v.password.password_old.$error"
            outlined
            @click:append="showPassOld = !showPassOld"
            @change="password.badPassword = false"
          />

          <v-text-field
            :type="showPass ? 'text' : 'password'"
            name="password"
            v-model="password.password"
            :error="$v.password.password.$error"
            :label="$t('password.new')"
            :placeholder="$t('password.min_length')"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            color="primary"
            outlined
            loading
            @click:append="showPass = !showPass"
          >
            <template v-slot:progress>
              <v-progress-linear
                style="
                  width: 98%;
                  border-bottom-left-radius: 30px;
                  border-bottom-right-radius: 30px;
                "
                class="mx-1"
                :value="passwordScore.value"
                :color="passwordScore.color"
                absolute
                height="7"
              ></v-progress-linear>
            </template>
          </v-text-field>

          <v-btn type="submit" color="primary" :loading="password.isResetting" block x-large>
            {{ $t('password.edit') }}
          </v-btn>
        </form>
      </tab>

      <tab id="signature" :name="$t('general.signature')">
        <div class="mx-auto" style="margin-bottom: 30px; max-width: 22rem">
          <ColorInput
            v-model="application.signature_color"
            :label="`${$t('account.signature_color')}`"
            default="#000000"
            @input="setColor"
            @save="updateApplicationSettings()"
          />
        </div>

        <div class="" v-if="signature.isLoading">
          <p class="text-center">
            {{ $t('account.uploading_your_signature') }}
          </p>
        </div>
        <div class="" v-else-if="signature.imageDataUri">
          <p class="text-center">{{ $t('account.your_saved_signature') }}</p>
          <v-img class="account-signature" :src="signature.imageDataUri" contain>
            <template v-slot:placeholder>
              <ImageLoader />
            </template>
          </v-img>
        </div>
        <div class="" v-else>
          <p class="text-center">
            {{ $t('account.any_signature_for_your_profile') }}
          </p>
        </div>

        <div v-if="!signature.isLoading" class="profile-buttons" style="text-align: center">
          <div v-if="signature.imageDataUri">
            <v-btn color="primary" min-width="18rem" outlined x-large @click="deleteSignature()">
              {{ $t('signature.remove') }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              type="submit"
              color="primary"
              min-width="18rem"
              x-large
              @click="updateSignature()"
            >
              {{
                signature.imageDataUri
                  ? $t('account.new_signature')
                  : $t('account.insert_signature')
              }}
            </v-btn>
          </div>
        </div>
      </tab>
    </tabs>
  </Data>
</template>

<script>
import { hostname } from '@/services/helpers/variables.js';
import { mapGetters } from 'vuex';
import { obj_clone } from '@/services/helpers/clone.js';
import { required, minLength } from 'vuelidate/lib/validators';
import { changeAndSaveLocale } from '@/plugins/i18n';
import {
  deleteAvatar,
  deleteUserSignature,
  getUserSignature,
} from '@account/services/accountServices';
import { UserService } from '@/services/UserService.js';
import Cropper from 'cropperjs';
import FileUpload from 'vue-upload-component';
import Tab from '@/components/Tab.vue';
import Tabs from '@/components/Tabs.vue';
import ColorInput from '@/components/ColorInput.vue';
import InputPIN from '@/components/InputPIN.vue';
import LanguageSelect from '@/plugins/i18n/LanguageSelect';
import SigniModal from '@/components/SigniModal';
import zxcvbn from 'zxcvbn';
import SPhoneNumberInput from '@/common/components/SPhoneNumberInput';
import ImageLoader from '@/common/skeletons/ImageLoader';

export default {
  name: 'Account',
  components: {
    ImageLoader,
    SPhoneNumberInput,
    SigniModal,
    Tab,
    Tabs,
    FileUpload,
    ColorInput,
    InputPIN,
    LanguageSelect,
  },
  data() {
    return {
      deletingAvatar: false,
      pinCode: '',
      loadingPhone: false,
      loadingConfirmPin: false,
      confirmChangePhone: false,
      url: hostname + '/api/v1/users/avatar',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      selectClicked: false,
      checkbox2FA: true,
      files: [],
      edit: false,
      cropper: false,
      passwordNew: '',
      passwordCheck: '',
      showPass: false,
      showPassOld: false,
      showPassPhone: false,
      account: {
        firstname: '',
        lastname: '',
        email: '',
        locale: null,
      },
      application: {
        signature_color: '#000000',
        previousSignatureColor: '#000000',
        awaitingForSignatureColorChange: false,
      },
      password: {
        badPassword: false,
        isResetting: false,
        password: '',
        password_old: '',
      },
      phone: {
        mobile: '',
        password: '',
        phoneNumberValid: true,
      },
      number: null,
      signature: {
        isLoading: false,
        imageDataUri: '',
      },
      setTab: '',
      selectedTab: '',
    };
  },
  validations: {
    account: {
      firstname: {
        required,
        minLength: minLength(2),
      },
      lastname: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        minLength: minLength(2),
      },
    },
    password: {
      password: {
        required,
        minLength: minLength(8),
      },
      password_old: {
        required,
        minLength: minLength(8),
      },
    },
    phone: {
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  created() {
    this.account = obj_clone(this.profile);
    this.phone.mobile = this.profile.mobile;
    this.application.signature_color = this.profile.signature_color;
    this.application.previousSignatureColor = this.profile.signature_color;
  },
  computed: {
    ...mapGetters({
      accountSelectedTab: 'userSettings/accountSelectedTab',
      isMobileDevice: 'window/isMobileDevice',
      profile: 'profile',
    }),
    profileAvatar() {
      if (this.profile.avatar_url) {
        return this.profile.avatar_url;
      }

      return 'images/user.svg';
    },
    passwordScore() {
      const result = zxcvbn(this.password.password);

      switch (result.score) {
        case 4:
          return {
            color: 'success',
            value: 100,
          };
        case 3:
          return {
            color: 'success',
            value: 75,
          };
        case 2:
          return {
            color: 'warning',
            value: 50,
          };
        case 1:
          return {
            color: 'error',
            value: 25,
          };
        default:
          return {
            color: 'error',
            value: 0,
          };
      }
    },
  },
  methods: {
    deleteAvatar() {
      this.deletingAvatar = true;

      deleteAvatar().then(
        () => {
          this.$store.dispatch('getUserProfile');
          this.deletingAvatar = false;
        },
        () => {
          this.$notification.error(this.$t('general.error'));
          this.deletingAvatar = false;
        },
      );
    },
    updateAccount() {
      this.$v.account.$touch();
      if (this.$v.account.$error) return;

      UserService.update(this.account, this.profile.id).then(
        () => {
          this.$store.dispatch('getUserProfile');
          changeAndSaveLocale(this, this.account.locale);
          this.$notification.success(this.$t('account.edit.ok'));
        },
        () => {
          this.$notification.error(this.$t('account.edit.failed'));
        },
      );
    },
    updateApplicationSettings() {
      const data = {
        signature_color: this.application.signature_color,
      };

      UserService.update(data, this.profile.id)
        .then(() => {
          this.$store.dispatch('getUserProfile');
        })
        .catch(() => {
          this.$notification.error(this.$t('account.edit.failed'));
        });
    },
    updatePhone() {
      this.loadingPhone = true;
      this.$v.phone.$touch();

      if (this.$v.phone.$error) {
        this.loadingPhone = false;
        return;
      }

      if (!this.phoneNumberValid) {
        this.$notification.error(this.$t('error.phone_number.bad_format'));
        this.loadingPhone = false;
        return;
      }

      UserService.requestChangeMobile({
        mobile: this.phone.mobile,
        password: this.phone.password,
      }).then(
        (resp) => {
          this.$notification.success(this.$t('account.send_pin.ok'));
          this.confirmChangePhone = 1;
          this.loadingPhone = false;
          this.$v.$reset();

          if (typeof resp.smsPin !== 'undefined') {
            setTimeout(() => {
              this.pinCode = resp.smsPin;
              this.confirmPin();
            }, 800);
          }
        },
        (err) => {
          if ('string' === typeof err || err instanceof String) {
            this.$notification.error(err);
          } else {
            this.$notification.error(this.$t('account.send_pin.error'));
          }

          this.loadingPhone = false;
        },
      );
    },
    confirmPin() {
      if ('' === this.pinCode) {
        this.$notification.error(this.$t('two_factor_auth.enter_pin_heading_heading'));
        return;
      }

      this.loadingConfirmPin = true;

      UserService.confirmPin({ pin: this.pinCode }).then(
        () => {
          this.confirmChangePhone = 2;
          this.loadingConfirmPin = false;
          this.$notification.success(this.$t('account.phone_edited'));
          this.phone.password = '';

          this.$refs.inputPin.resetPin();
          this.$store.dispatch('getUserProfile');
        },
        (errorMessage) => {
          this.loadingConfirmPin = false;
          this.$notification.error(errorMessage);
        },
      );
    },
    updatePassword() {
      this.password.isResetting = true;
      this.$v.password.$touch();

      if (this.$v.password.$error) {
        this.$notification.error(this.$t('password.required'));
        this.password.isResetting = false;

        return;
      }

      UserService.update(this.password, this.profile.id).then(
        () => {
          this.$store.dispatch('getUserProfile');
          this.$notification.success(this.$t('password.change.ok'));
          this.password.password = '';
          this.password.password_old = '';
          this.password.isResetting = false;
          this.$v.$reset();
        },
        (errorMessage) => {
          this.$notification.error(errorMessage);
          this.password.isResetting = false;
          this.password.badPassword = true;
        },
      );
    },
    tabChanged(selectedTab) {
      this.selectedTab = selectedTab.tab.name;
      this.selectClicked = false;

      window.location.hash = `#${selectedTab.tab.id}`;

      if ('signature' === selectedTab.tab.id) {
        this.fetchUserSignature();
      }
    },
    fetchUserSignature() {
      this.signature.isLoading = true;

      getUserSignature()
        .then((response) => {
          this.signature.imageDataUri = response.file_url;
        })
        .catch((err) => {
          if (err?.startsWith('Signature not found for user')) {
            return;
          }
          this.$notification.error(this.$t('contract.errors.signature_not_loaded'));
        })
        .finally(() => {
          this.signature.isLoading = false;
        });
    },
    updateSignature() {
      if (this.application.previousSignatureColor !== this.profile.signature_color) {
        this.updateApplicationSettings();
      }

      this.$router.push({
        name: 'accountSignature',
        query: {
          ...this.$route.query,
          newSignature: true,
        },
      });
    },
    deleteSignature() {
      deleteUserSignature().then(() => {
        this.signature.imageDataUri = '';
      });
    },
    arrayBufferToBase64(buffer) {
      let binary = '';
      let bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach((b) => (binary += String.fromCharCode(b)));

      return window.btoa(binary);
    },
    displaySelect() {
      this.selectClicked = !this.selectClicked;
    },
    setColor(hex) {
      this.application.previousSignatureColor = this.application.signature_color;
      this.application.signature_color = hex;
    },
    editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1]);
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true,
      });
    },
    alert(message) {
      alert(message);
    },
    // eslint-disable-next-line no-unused-vars
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true;
        });
      }

      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture');
          return prevent();
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = '';
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return;
          }
          this.cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1,
            viewMode: 1,
          });
        });
      } else {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    },

    profile: function () {
      this.account = obj_clone(this.profile);
      this.phone.mobile = this.profile.mobile;
      this.application.signature_color = this.profile.signature_color;
    },

    files: function () {
      if (this.files.length > 0 && this.files[0].success) {
        // this.user.avatar = this.files[0].response.user.avatar;

        this.$store.dispatch('getUserProfile');
      }
    },
  },
};
</script>

<style lang="scss">
.profile-delete {
  margin-top: 100px;
  text-align: center;

  .btn {
    color: #000 !important;
  }
}

#change-password {
  width: 340px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 100%;
  padding: 0 15px;
}

#personal-information {
  width: 340px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 100%;
  padding: 0 15px;
}

#phone {
  width: 340px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 100%;
  padding: 0 15px;
}

#application {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 100%;
  padding: 0 15px;
}

.account-signature {
  background: white unset no-repeat center;
  background-size: contain;
  max-width: 400px;
  width: 100%;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -16px;
  border-radius: 8px;
}

.avatar-edit-image {
  height: 340px !important;
}

.tabs-component-panels {
  position: relative;
  z-index: 5;
}

.avatar {
  @include clearfix;
}

.avatar-edit-title {
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.03px;
  font-weight: normal;
}

.avatar-edit-button {
  font-weight: 300;
  font-size: 16px;
  color: #5e239e;
  letter-spacing: -0.03px;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.avatar__text {
  width: 50%;
  float: left;
  text-align: left;
  margin-top: 54px;
  transform: translateY(-50%);
}
.avatar__image {
  width: 50%;
  float: left;
  text-align: right;
}

.example-avatar .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}

.example-avatar .text-center .btn {
  margin: 0 0.5rem;
}

.example-avatar .avatar-edit-image {
  max-width: 100%;
}

.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.example-avatar .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cropper-container img {
  display: block;
  height: 100%;
  image-orientation: 0deg;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
}

.cropper-modal {
  background-color: #000;
  opacity: 0.5;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: rgba(51, 153, 255, 0.75) solid 1px;
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;
}

.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: calc(100% / 3);
  left: 0;
  top: calc(100% / 3);
  width: 100%;
}

.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: calc(100% / 3);
  top: 0;
  width: calc(100% / 3);
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;
}

.cropper-center::before,
.cropper-center::after {
  background-color: #eee;
  content: ' ';
  display: block;
  position: absolute;
}

.cropper-center::before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px;
}

.cropper-center::after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px;
}

.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0;
}

.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%;
}

.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px;
}

.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%;
}

.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px;
}

.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px;
}

.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}

.cropper-point.point-se::before {
  background-color: #39f;
  bottom: -50%;
  content: ' ';
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}

.account__language-select {
  position: relative;
  width: 100%;

  .language-select-active {
    width: 80% !important;
    padding: 0.5rem 2rem !important;
    margin: 0 auto !important;

    .icon-bottom {
      right: 1rem !important;
    }

    svg {
      zoom: 0.8;
      border-radius: 4px;
    }
  }

  .language-select-options {
    width: 80%;
    left: 50% !important;

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 2rem !important;

      span {
        height: unset !important;
        padding: 0.5rem 0;
      }
    }

    svg {
      zoom: 0.8;
      border-radius: 4px;
    }
  }
}
</style>
